import { FC } from 'react';
import style from './wrapper/Wrapper.module.scss';

type Props = {
  children: React.ReactNode;
};

const Wrapper: FC<Props> = ({ children }) => {
  return <div className={style.wrapper}>{children}</div>;
};

export default Wrapper;
