import style from "./header/Header.module.scss";
import logo from "../styles/images/logo.svg";
import phone from "../styles/images/phone.svg";

const Header = () => {
  return (
    <div className={style.header}>
      <div className={style.logo}>
        <img className={style.image} src={logo} alt="logo" />
        <span className={style.title}>Masáže Denisa</span>
      </div>
      <div
        className={style.contact}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            marginRight: "20px",
            color: "#3c61ff",
            fontWeight: "bolder",
          }}
        >
          +421 949 883 443
        </div>
        <a href="#offer" className={style.callBtn}>
          <img src={phone} className={style.icon} alt="call us" />
          <div className={style.btnText}>Zavolajte nám</div>
        </a>
      </div>
    </div>
  );
};

export default Header;
