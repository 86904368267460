import Seo from "./components/Seo";
import "./styles/font.scss";
import "./styles/normalize.scss";

import Wrapper from "./components/Wrapper";
import Header from "./components/Header";
import BannerSection from "./components/BannerSection";
import Content from "./components/Content";
import Offer from "./components/Offer";
import Footer from "./components/Footer";
import { BrowserRouter } from "react-router-dom";
import ModalRoutes from "./components/ModalRoutes";

const App = () => {
  return (
    <Seo description="Masaze" >
      <BrowserRouter>
        <Wrapper>
          <Header />
          <BannerSection />
          <Content />
          <Offer />
          <Footer />
        </Wrapper>
        <ModalRoutes />
      </BrowserRouter>
    </Seo>
  );
};

export default App;
