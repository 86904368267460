import style from './footer/Footer.module.scss';

const Footer = () => {
  const date = new Date();

  return (
    <div className={style.footer}>Copyright @ {date.getUTCFullYear()}</div>
  );
};

export default Footer;
