import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title?: string;
  description: string;
}

const Seo: React.FC<Props> = (props) => (
  <>
    <Helmet>
      {!props.title ? (
        <title>Masáže</title>
      ) : (
        <title>{props.title} | MasazeDenisa.sk</title>
      )}
      <link rel="icon" href="../styles/images/logo.ico" type="image/x-icon" />
      <meta name="description" content={props.description} />
    </Helmet>
    {props.children}
  </>
);

export default Seo;
