import { FC } from 'react';
import style from './button/Button.module.scss';
import classes from 'react-style-classes';

type Props = {
  children: string | React.ReactNode;
  variant?: 'primary' | 'secondary';
  onClick?(): void;
  className?: string;
};

const Button: FC<Props> = ({
  variant = 'primary',
  children,
  onClick,
  className,
}) => {
  return (
    <div
      className={classes(style.btn, style[variant], className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Button;
