import Button from "../../ui/Button";
import style from "./bannerinfo/BannerInfo.module.scss";

const BannerInfo = () => {
  return (
    <div className={style.bannerInfo}>
      <h1 className={style.bannerTitle}>
        Chcete sa cítiť <span className={style.varA}>šťastne</span>, krásne,{" "}
        <span className={style.varB}>sviežo</span>, radostne a{" "}
        <span className={style.varC}>uvoľnene</span>?
      </h1>
      <div className={style.bannerText}>
        Objednajte sa u nás na terapiu a doprajte aj sami sebe.
      </div>
      <div className={style.buttons}>
        <Button className={style.large} variant="secondary">
          <a href="#content">Zistiť viac</a>
        </Button>
        <Button className={style.large}>
          <a href="#offer">Objednať teraz</a>
        </Button>
      </div>
    </div>
  );
};

export default BannerInfo;
