import style from "./banneritem/BannerItem.module.scss";

const BannerItem = () => {
  return (
    <div className={style.bannerItem}>
      <img
        className={style.bannerImage}
        src="https://wellnesspatince.sk/wp-content/uploads/2023/02/Wellness_Hotel_Patince_1920x1080_40.jpg"
        alt="flower"
      />
    </div>
  );
};

export default BannerItem;
