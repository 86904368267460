import { massages } from "../data/Massages";
import style from "./content/Content.module.scss";
import ContentItem from "./content/ContentItem";

const Content = () => {
  return (
    <div id="content" className={style.content}>
      <h1 className={style.contentTitle}>Čo ponúkame?</h1>
      {massages.map((m, i) => (
        <ContentItem key={`massage-${m.title}-${i}`} massage={m} />
      ))}
    </div>
  );
};

export default Content;
