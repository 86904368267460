import { Route, Routes, useLocation } from 'react-router-dom';
import MassageModal from './MassageModal';

const ModalRoutes = () => {
  const location = useLocation()
  const state = location.state as {backgroundLocation?: Location}

  return (
    <Routes location={state?.backgroundLocation || location} >
      <Route path='/massage/:name/:id' element={<MassageModal />} />
    </Routes>
  );
};

export default ModalRoutes;
