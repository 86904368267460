import BannerInfo from "./bannersection/BannerInfo";
import BannerItem from "./bannersection/BannerItem";
import style from "./bannersection/BannerSection.module.scss";

const BannerSection = () => {
  return (
    <div className={style.bannerSection}>
      <BannerInfo />
      <BannerItem />
    </div>
  );
};

export default BannerSection;
