import { time } from "console";
import { Router, useNavigate, useParams } from "react-router-dom";
import { massages } from "../data/Massages";
import Button from "../ui/Button";
import style from "./massageModal/MassageModal.module.scss";

const MassageModal = () => {
  const navigate = useNavigate();
  const handleClose = (e: any) => {
    if (e.target.className === style.modal) {
      document.body.classList.remove("oh");
      navigate(-1);
    }
    if (e.target.className === style.x) {
      document.body.classList.remove("oh");
      navigate(-1);
    }
  };

  window.onkeydown = (e) => {
    if (e.keyCode === 27) {
      navigate(-1);
    }
  };
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  const { name } = useParams();
  const Massage = massages.find((x) => x.title === name);
  const onSubmit = () => {
    if (document != null) {
      const element = document.getElementById("offer");
      navigate("/");

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
      document.body.classList.remove("oh");
    }
  };

  return (
    <div className={style.modal} onClick={handleClose}>
      <div className={style.content}>
        <div
          className={style.x}
          style={{ fontSize: "24px", float: "right", cursor: "pointer" }}
          onClick={() => {
            navigate("../../");
            document.body.classList.remove("oh");
          }}
        >
          x
        </div>
        <div className={style.img}>
          <img src={Massage?.img} alt="" />
        </div>
        <div className={style.title}>{Massage?.title}</div>
        <div className={style.desc}>{Massage?.desc}</div>

        <Button onClick={onSubmit} className={style.btn}>
          Objednať teraz
        </Button>
      </div>
    </div>
  );
};

export default MassageModal;
