import { FC } from "react";
import { Link } from "react-router-dom";
import { Massage } from "../../types/Massage";
import Button from "../../ui/Button";
import style from "./contentitem/ContentItem.module.scss";

type Props = {
  massage: Massage;
};

const ContentItem: FC<Props> = ({ massage }) => {
  console.log(massage.href);
  return (
    <div className={style.contentItem}>
      <div className={style.imageWrapper}>
        <Link
          className={style.buttonWrapper}
          onClick={() => document.body.classList.add("oh")}
          to={`/massage/${massage.title}/${massage.id}`}
        >
          <img className={style.contentImage} src={massage.img} alt="flower" />
        </Link>
      </div>
      <h1 className={style.contentItemTitle}>{massage.title}</h1>
      <div className={style.contentInfo}>
        {massage.desc.substring(0, 200)}...
      </div>
      <div className={style.prizeWrapper}>
        <div className={style.currency}>{massage.currency}</div>
      </div>
      <div className={style.buttons}>
        <Link
          className={style.buttonWrapper}
          onClick={() => document.body.classList.add("oh")}
          to={`/massage/${massage.title}/${massage.id}`}
        >
          <Button variant="secondary" className={style.btn}>
            Zistiť viac
          </Button>
        </Link>
        <Button className={style.btn2}>
          <a href={massage.href ? massage.href : "#offer"}>Objednať teraz</a>
        </Button>
      </div>
    </div>
  );
};

export default ContentItem;
