import style from "./map/CustomMap.module.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Marker } from "react-map-gl";

export type CustomMapProps = {
  longtitude: number;
  latitude: number;
  name?: string;
  price?: string;
  zoom?: number;
};

const CustomMap = ({
  longtitude,
  latitude,
  name,
  price,
  zoom = 7,
}: CustomMapProps) => {
  return (
    <div className={style.MapWrapper}>
      <div className={style.Map}>
        <Map
          initialViewState={{
            longitude: longtitude,
            latitude: latitude,
            zoom: zoom,
          }}
          mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
          mapboxAccessToken={
            "pk.eyJ1IjoibWF0ZWpiYWJpayIsImEiOiJjbGUwMW93OXgwMmgzM3ZtdnFjaGd4bHdpIn0.8OnLrBNFY6V9y0tKWsTKIA"
          }
        >
          <Marker longitude={longtitude} latitude={latitude} anchor="bottom">
            <div style={{ textAlign: "center", cursor: "default" }}>
              {name && (
                <div className={style.TextWrapper}>
                  <div>{name}</div>
                  <div>{price && price}</div>
                </div>
              )}
              <img
                width={30}
                height={30}
                src="https://www.freepnglogos.com/uploads/pin-png/location-pin-connectsafely-37.png"
                alt="map pin"
              />
            </div>
          </Marker>
        </Map>
      </div>
    </div>
  );
};

export default CustomMap;
